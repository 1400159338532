var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-lg-5 px-4 settings-content",staticStyle:{"max-width":"425px"}},[_c('div',{staticClass:"row mx-0 block_options"},[_c('p',{staticClass:"col-12 mt-4 mb-0 d-flex px-0 settings-text gray-color"},[_vm._v(" How do you prefer the tracks in your playlist(s) displayed? ")]),_c('v-select',{staticClass:"col-12 px-0",staticStyle:{"margin-bottom":"15px","margin-top":"10px"},attrs:{"label":"label","clearable":false,"options":[].concat( _vm.selectOrder ),"searchable":false,"value":_vm.userCopy.tracksDisplayingPreference},on:{"option:selected":function($event){return _vm.UPDATE_USER_COPY({
          tracksDisplayingPreference: $event
        })}},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
        var attributes = ref.attributes;
return [_c('img',_vm._b({attrs:{"src":require("@/assets/images/arrow-playlist-optionsOne.svg")}},'img',attributes,false))]}}])})],1),_c('div',{staticClass:"row mx-0 block_options"},[_c('p',{staticClass:"col-12 mb-0 px-0 settings-text gray-color"},[_vm._v(" How do you prefer your playlist(s) displayed publicly? ")]),_c('v-select',{staticClass:"col-12  px-0",staticStyle:{"margin-bottom":"15px","margin-top":"10px"},attrs:{"label":"label","clearable":false,"options":[].concat( _vm.selectFanOrder ),"searchable":false,"value":_vm.selectedOptionHide,"disabled":_vm.isHideTrack()},on:{"option:selected":function($event){return _vm.UPDATE_USER_COPY({
          playlistsDisplayingPreference: $event
        })}},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
        var attributes = ref.attributes;
return [_c('img',_vm._b({attrs:{"src":require("@/assets/images/arrow-playlist-optionsOne.svg")}},'img',attributes,false))]}}])})],1),_c('div',{staticClass:"row mx-0 block_feuture"},[_vm._m(0),_c('v-select',{staticClass:"col-12  px-0",staticStyle:{"margin-bottom":"15px","margin-top":"10px"},attrs:{"label":"label","clearable":false,"options":[].concat( _vm.selectFeature ),"searchable":false,"value":_vm.userCopy.tracksPrivacyPreference},on:{"option:selected":function($event){return _vm.UPDATE_USER_COPY({
          tracksPrivacyPreference: $event
        })}},scopedSlots:_vm._u([{key:"open-indicator",fn:function(ref){
        var attributes = ref.attributes;
return [_c('img',_vm._b({attrs:{"src":require("@/assets/images/arrow-playlist-options.svg")}},'img',attributes,false))]}}]),model:{value:(_vm.userCopy.tracksPrivacyPreference),callback:function ($$v) {_vm.$set(_vm.userCopy, "tracksPrivacyPreference", $$v)},expression:"userCopy.tracksPrivacyPreference"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"col-12 mb-0 px-0 settings-text gray-color"},[_c('span',[_vm._v(" BETA FEATURE: ")]),_c('br'),_vm._v(" Hide Details Pro Feature - Turning this on will allow you to share with the producer without revealing the track you played. Playlist minimum increases to 30 €/$/£ per playlist. ")])}]

export { render, staticRenderFns }